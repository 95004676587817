import getConfig from 'next/config'

import type { Context as CartContext } from '@grandvisionhq/carts-v2'
import type { CheckoutSettings, Consent, Routes } from '@grandvisionhq/checkout-v2'
import type { IntlContextModel } from '@grandvisionhq/state'
import { Util as StoreUtils } from '@grandvisionhq/stores-v2'

interface SettingsInput {
  cart: CartContext.CartContextModel['cart']
  intl: IntlContextModel
}

const { publicRuntimeConfig } = getConfig()
const { orderTimeOut } = publicRuntimeConfig.checkout

const basePath = '/checkout'
const routes: Routes = {
  shipping: {
    label: 'Envío',
    path: `${basePath}/shipping`,
  },
  overview: {
    label: 'Resumen',
    path: `${basePath}/overview`,
  },
  payment: {
    label: 'Pago',
    path: `${basePath}/payment`,
  },
}

const getCheckoutConsents = ({
  cart,
  intl,
}: {
  cart: CartContext.CartContextModel['cart']
  intl: IntlContextModel
}): Consent[] => {
  const { getLabel, getFormattedLabel } = intl
  const hasContactLenses = cart?.contains.contactLenses
  const hasSolutions = cart?.contains.solutions

  const allConsents: Consent[] = [
    {
      title:
        getFormattedLabel('checkout.consent.conditions', {
          parseLink: true,
          openLinksInNewTab: true,
          parseHTML: false,
        }) + '&nbsp;*',
      value: 'terms-conditions',
      required: true,
      errorMessage: getLabel('checkout.consent.conditions.error'),
    },
  ]

  // Add medical consent when cart contains Contact-lenses OR Solutions
  if (hasContactLenses || hasSolutions) {
    allConsents.push({
      title: getLabel('checkout.consent.medical') + '&nbsp;*',
      value: 'medical-consent',
      required: true,
      errorMessage: getLabel('checkout.consent.medical.error'),
    })
  }

  return allConsents
}

export const getCheckoutConfig = ({ cart, intl }: SettingsInput): CheckoutSettings => {
  const checkoutSettings = {
    account: {
      implicitAccountCreation: true,
      createAccountRequired: true,
    },
    consent: {
      items: getCheckoutConsents({ intl, cart }),
      implicitTermsAndConditions: false,
    },
    shippingDetails: {
      loadingFinished: Boolean(cart) || cart === null,
      ...(cart?.billingAddress ? { billingAddress: cart.billingAddress } : {}),
      ...(cart?.shippingAddress ? { deliveryAddress: cart.shippingAddress } : {}),
      ...(cart?.customerDetails ? { customer: cart.customerDetails } : {}),
    },
    shippingFormFields: {
      billingAddress: [
        'firstName',
        'lastName',
        'streetName',
        'additionalStreetInfo',
        'postalCode',
        'city',
        'region',
      ],
      contactDetails: ['email', 'phone', 'dateOfBirth'],
    },
    storeFinder: {
      showUseMyLocation: true,
      searchWhitelist: ['storeName', 'postalCode', 'streetName', 'town'] as StoreUtils.SearchKey[],
    },
    ...(orderTimeOut ? { orderCreationTimeOutLimit: orderTimeOut } : {}),
    features: {
      isCompanyEnabled: true,
      isServiceStoreEnabled: false,
    },
    login: {
      disableSignup: true,
      returnPath: cart ? `${routes.shipping?.path}?cartId=${cart.id}` : routes.shipping?.path,
    },
    payments: {
      applePay: {
        merchantName: 'Optica 2000',
      },
    },
    routes,
  }

  return checkoutSettings
}
